var settings = [
    {
      //"host": "http://167.114.217.178", //http://200.98.200.44" api //"http://167.114.217.178
      "host": "http://localhost", //http://200.98.200.44" api //"http://167.114.217.178
      "port": 3001,
      //"frontUri": "http://167.114.217.178:3012" //localhost:3012 front end //http://167.114.217.178:3012
      "frontUri": "http://localhost:3012" //localhost:3012 front end //http://167.114.217.178:3012
    }
  ]
  export { settings };
  