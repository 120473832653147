// dateRanges.js

import { addDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addWeeks, addMonths } from 'date-fns';

export const customStaticRanges = [
  {
    label: 'Hoje',
    range: () => ({ startDate: new Date(), endDate: new Date() }),
    isSelected: (dateRange) => {
      const today = new Date();
      return (
        dateRange.startDate.toDateString() === today.toDateString() &&
        dateRange.endDate.toDateString() === today.toDateString()
      );
    },
  },
  {
    label: 'Ontem',
    range: () => ({
      startDate: addDays(new Date(), -1),
      endDate: addDays(new Date(), -1),
    }),
    isSelected: (dateRange) => {
      const yesterday = addDays(new Date(), -1);
      return (
        dateRange.startDate.toDateString() === yesterday.toDateString() &&
        dateRange.endDate.toDateString() === yesterday.toDateString()
      );
    },
  },
  {
    label: 'Esta Semana',
    range: () => ({
      startDate: startOfWeek(new Date()),
      endDate: new Date(),
    }),
    isSelected: (dateRange) => {
      const start = startOfWeek(new Date());
      const end = new Date();
      return (
        dateRange.startDate.toDateString() === start.toDateString() &&
        dateRange.endDate.toDateString() === end.toDateString()
      );
    },
  },
  {
    label: 'Semana Passada',
    range: () => ({
      startDate: startOfWeek(addWeeks(new Date(), -1)),
      endDate: endOfWeek(addWeeks(new Date(), -1)),
    }),
    isSelected: (dateRange) => {
      const start = startOfWeek(addWeeks(new Date(), -1));
      const end = endOfWeek(addWeeks(new Date(), -1));
      return (
        dateRange.startDate.toDateString() === start.toDateString() &&
        dateRange.endDate.toDateString() === end.toDateString()
      );
    },
  },
  {
    label: 'Este Mês',
    range: () => ({
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
    }),
    isSelected: (dateRange) => {
      const start = startOfMonth(new Date());
      const end = new Date();
      return (
        dateRange.startDate.toDateString() === start.toDateString() &&
        dateRange.endDate.toDateString() === end.toDateString()
      );
    },
  },
  {
    label: 'Mês Passado',
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    }),
    isSelected: (dateRange) => {
      const start = startOfMonth(addMonths(new Date(), -1));
      const end = endOfMonth(addMonths(new Date(), -1));
      return (
        dateRange.startDate.toDateString() === start.toDateString() &&
        dateRange.endDate.toDateString() === end.toDateString()
      );
    },
  },
];
