import React, { useState, useRef, Component, useEffect } from 'react';

import './App.css';
import logo from './imagens/Capivari_Logo_fundosclaros.png'
import { settings } from './settings'
import BarcodeGenerator from './BarcodeGenerator';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { ptBR } from 'date-fns/locale';
import {customStaticRanges} from './localeDatePicker'

let host
let port
settings.map((item, index) => {
  console.log(item)
  host = item.host
  port = item.port
})



const App = () => {


  const [dataTags, setDataTags] = useState()
  const [inputValue, setInputValue] = useState('')
  const [serialNumbers, setSerialNumbers] = useState([])
  const [searchTag, setSearchTerm] = useState('');
  const [searchDescript, setSearchDescript] = useState('');
  const [searchType, setSearchType] = useState('');
  const [orderBy, setOrderBy] = useState('asc'); // Valor inicial definido como 'asc'
  const [flagDate, setFlagDate] = useState(false);


  const fetchData = async () => {
    try {
      // const response = await fetch(`http://localhost:3000/search?serialTag=${searchTag}&description=${searchDescript}&type=${searchType}&startDate="2024-04-01"&endDate="2024-05-30"&orderBy=${orderBy}`);
      // const response = await fetch(`http://localhost:3000/search?serialTag=${searchTag}&description=${searchDescript}&type=${searchType}&startDate=${(selectionRange.startDate)}&endDate=${selectionRange.endDate}=&flagDate=${flagDate}&orderBy=${orderBy}`);
      const response = await fetch(`http://77.37.68.73:3000/search?serialTag=${searchTag}&description=${searchDescript}&type=${searchType}&startDate=${(selectionRange.startDate)}&endDate=${selectionRange.endDate}=&flagDate=${flagDate}&orderBy=${orderBy}`);
      // const response = await fetch(`http://77.37.68.73:3000/search?serialTag=${searchTag}&description=${searchDescript}&type=${searchType}&orderBy=${orderBy}`);
      const jsonData = await response.json();
      console.log(jsonData)
      setDataTags("");
      setDataTags(jsonData);
    } catch (error) {
      console.error('Erro na requisição', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []); // Executa fetchData quando o componente monta

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleSelectDate = (ranges) => {
    setSelectionRange(ranges.selection);
  };



  const handleButtonClick = () => {
    fetchData();
  };

  const handleInputChangeTag = (e) => {
    setSearchTerm(e.target.value)
  };
  const handleInputChangeDescrip = (e) => {
    setSearchDescript(e.target.value)
  };
  const handleInputChangeType = (e) => {
    setSearchType(e.target.value)
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  const handleFlagDateChange = (event) => {
    setFlagDate(event.target.checked);
  };

  function Header() {
    return (
      <header style={{
        backgroundColor: '#eee',
        padding: '20px',
        textAlign: 'center',
        height: 60,
      }}>
        <img src={logo} alt="Logotipo" style={{
          width: 100,
          height: 40,
        }} />
      </header>
    );
  }

  return (
    <div className="App">
      <Header />

      <div className="App-body">
        <div className="container text-left" style={{ margig: 5 }}>
          <div className='container'>
            <div className="row">
              <div className="col-2">
                <h4>Procurar por:</h4>
              </div>
              <div className="col-2">
                <input type="text" className="form-control" name="tag" id="inputTag" value={searchTag} onChange={handleInputChangeTag} placeholder='Serial' />
              </div>
              <div className="col-2">
                <input type="text" className="form-control" name="descricao" id="inputDescrip" value={searchDescript} onChange={handleInputChangeDescrip} placeholder='Descrição' />
              </div>
              <div className="col-2">
                {/* <input type="text" className="form-control" name="type" id="inputType" value={searchType} onChange={handleInputChangeType} placeholder='Tipo de evento' /> */}
                <select className='form-select ' value={searchType} onChange={handleInputChangeType}>
                  <option value="">Tipo de evento</option>
                  <option value="1">CADASTRO</option>
                  <option value="2">ENTRADA FÁBRICA</option>
                  <option value="3">SAÍDA FÁBRICA</option>
                  <option value="4">ENTRADA PÁTIO 1</option> 
                  <option value="5">SAÍDA PÁTIO 1</option>
                  <option value="6">ENTRADA PÁTIO 2</option>
                  <option value="7">SAÍDA PÁTIO 2</option>
                  <option value="8">ENTRADA PÁTIO 3</option> 
                  <option value="9">SAÍDA PÁTIO 3</option>
                </select>
              </div>
              <div className="col-2" style={{ fontSize: '16px' }}>
                <label>
                  <input
                    type="radio"
                    value="asc"
                    checked={orderBy === 'asc'}
                    onChange={handleOrderByChange}
                  />
                  Cres.
                </label>
                <label>
                  <input
                    type="radio"
                    value="desc"
                    checked={orderBy === 'desc'}
                    onChange={handleOrderByChange}
                  />
                  Decres.
                </label>
              </div>
              <div className="col-1">
                <button type="button" className="btn btn-primary" onClick={handleButtonClick}>Buscar</button>
              </div>
              <div className="input-group">
                <div className="form-check form-switch">
                  <input className="form-check-input" 
                    type="checkbox" 
                    role="switch"
                    id="flagDate"
                    checked={flagDate}
                    onChange={handleFlagDateChange}
                    aria-label="Radio button for following text input"/>
                </div>
                <label className="form-check-label" for="flagDate">Pesquisar por data</label>
              </div>
            </div>
            <div className="row">
            <div className="col-12">
                {flagDate ? (<DateRangePicker  ranges={[selectionRange]} 
                onChange={(ranges) => setSelectionRange(ranges.selection)}
                locale={ptBR}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
                staticRanges={customStaticRanges}
                />) : null}
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {dataTags ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th>Serial</th>
                        <th>Descrição</th>
                        <th>Tipo do Lote</th>
                        <th>Data do evento</th>
                        <th>Cod. Registro</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataTags.map((data, index) => (
                        <tr key={data.index}>
                          <td>{index}</td>
                          <td>{data.SerialTag}</td>
                          <td>{data.descricao}</td>
                          <td>{data.tipoDoLote}</td>
                          <td>{new Date(data.data_criacao).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
                          <td><BarcodeGenerator value={data.SerialTag} /></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
          </div>
          <blockquote />
        </div>
      </div >
    </div >
  );
}

export default App;
