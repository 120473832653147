import React, { useRef, useEffect } from 'react';
import JsBarcode from 'jsbarcode';

function BarcodeGenerator({ value }) {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, value);
    }
  }, [value]);

  return <svg ref={barcodeRef}></svg>;
}

export default BarcodeGenerator;
